import React from 'react';
import {
  makeStyles
} from '@material-ui/core';
import { ReactComponent as Icon } from 'src/assets/images/logo-metha-completo.svg';
const useStyles = makeStyles((theme) => ({
  logo: {
    height: 40,
    [theme.breakpoints.down('xs')]: {
      height: 30,
    },
    fill: theme.palette.logo.main
  }
}));
function Logo(props) {
  const classes = useStyles();
  return (
    <Icon className={classes.logo} {...props} />
  );
}

export default Logo;
