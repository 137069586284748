import React from "react";
import drizzleOptions from "./drizzleOptions";
import store from './middleware'
import { throttle, isEqual } from "lodash";
import { Drizzle } from "@drizzle/store";
import { setConnectionStatus, setConnectionData } from 'src/actions/dappActions';
import { connect } from 'react-redux'
const config = require('src/dapp-config.json')
const Context = React.createContext();

class Provider extends React.Component {

  constructor(props, context) {
    super(props);
    this.state = {
      initialized: false,
      noThrottle: true,
      drizzleState: null
    }
    this.throttledSetState = throttle((state) => {
      this.setState({ ...state });
    }, 3000)
    this.drizzle = new Drizzle(drizzleOptions, store);
    this.throttledUpdateState = throttle(this.updateState, 2500); //Throttle del update state
  }

  componentDidMount() {
    // subscribe to changes in the store, keep state up-to-date
    this.subscribe();
    this.interval = setInterval(this.subscribe, 4000);  //Intervalo de refresco 
  }

  subscribe = () => {
    this.unsubscribe = this.drizzle.store.subscribe(() => {
      const drizzleState = this.drizzle.store.getState();
      if (drizzleState.drizzleStatus.initialized) {
        this.dispatchStatus("connected");
        this.props.dispatch(setConnectionData({ network: drizzleState.web3.networkId, wallet: drizzleState.accounts[0] }));
        if (!this.state.drizzleState) setTimeout(() => this.setState({ noThrottle: false }), 3000); //Tiempo en el que el throttle esta desactivado para el init
        if (this.state.noThrottle) this.updateState(drizzleState);
        else this.throttledUpdateState(drizzleState);
      } else {
        if (drizzleState.web3.status && drizzleState.web3.status == "initialized" && drizzleState.web3.network != config.network) {
          this.dispatchStatus("wrongNetwork");
        } else {
          this.dispatchStatus("error");
        }
      }
    });
    setTimeout(() => {
      if (typeof this.unsubscribe == "function") this.unsubscribe();
    }, 3000); //Duración del subscribe 3000
  }

  updateState = (drizzleState) => {
    this.setState({ drizzleState, initialized: true });
  }

  dispatchStatus = (status) => {
    this.props.dispatch(setConnectionStatus(status));
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    if (typeof this.unsubscribe == "function") this.unsubscribe();
  }

  render() {
    return (
      <Context.Provider
        value={{
          drizzle: this.drizzle,
          drizzleState: this.state.drizzleState,
          initialized: this.state.initialized
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default {
  Context: Context,
  Consumer: Context.Consumer,
  Provider: connect()(Provider)
};