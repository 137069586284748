import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { THEMES } from 'src/constants';
import { withContext } from 'src/context';
import DrizzleContext from "src/DrizzleContext";

const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
    ...(theme.name === THEMES.ONE_DARK || theme.name === THEMES.METHA) ? {
      backgroundColor: theme.palette.background.dark
    } :
      {
        background: "linear-gradient(rgba(249, 250, 252, 0.8), rgba(249, 250, 252, 1)), url(/static/images/dna_blockchain.jpg)"
      },
    backgroundPosition: "50% 50%"
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DrizzleProvider = ({ children, withDrizzle }) => {
  if (withDrizzle) {
    return (
      <DrizzleContext.Provider>
        {children}
      </DrizzleContext.Provider>
    )
  } else {
    return (
      <>
        {children}
      </>
    )
  }
}

function DashboardLayout({ children, withDrizzle }) {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <DrizzleProvider withDrizzle={withDrizzle}>
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              {children}
            </div>
          </div>
        </div>
      </DrizzleProvider>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any
};

export default withContext(DashboardLayout);
