import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';
import CachedIcon from '@material-ui/icons/Cached';
import {
  colors
} from '@material-ui/core';
import Label from 'src/components/Label';
import LogoSimple from 'src/components/LogoSimple';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    boxShadow: "unset"
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700,
    marginBottom: theme.spacing(2)
  },
  avatar: {
    backgroundColor: "red",
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: "red"
  },
  caption: {
    marginLeft: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(2)
  }
}));


const Budget = props => {
  const { className, value, pending, currentPeriod, claimButton, ...rest } = props;

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent style={{ margin: "auto", textAlign: "center" }} className={classes.content}>
        <Grid
          container
          justify="space-between"

        >

          <Grid item style={{ margin: "auto", textAlign: "center" }}>
            <Typography
              className={classes.title}
              gutterBottom
              variant="h5"
            >
              VAULT
            </Typography>
            <div style={{ display: "flex" }}>
              <Typography variant="h2" style={{ whiteSpace: "nowrap", margin: "auto", marginRight: 0 }} >{value} </Typography>
              < LogoSimple style={{ width: 30, height: 30, marginRight: "auto", marginLeft: 8 }} />
            </div>
            {pending &&
              <div className={classes.difference}>
                <div style={{ margin: "auto", display: "flex" }}>
                  <CachedIcon /* className={classes.differenceIcon}  */ />
                  <Typography
                    className={classes.caption}
                    variant="h6"
                  >
                    Period {currentPeriod}
                  </Typography>
                  <Label
                    color={colors.orange[600]}
                    variant="outlined"
                    className={classes.caption}
                  >
                    PENDING
          </Label>
                </div>

              </div>}
            {!pending &&
              <div className={classes.difference}>
                {/* <ArrowDownwardIcon className={classes.differenceIcon} /> */}
                {/* <Typography
                  className={classes.differenceValue}
                  variant="body2"
                >
                  0,2 ETH
           </Typography> */}
              </div>}
            <div className={classes.difference}>
              {claimButton}
            </div>
          </Grid>
        </Grid>

      </CardContent>
    </Card>
  );
};

Budget.propTypes = {
  className: PropTypes.string
};

export default Budget;
