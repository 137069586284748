import React, { useState, useEffect } from 'react';
import { Card, Typography, Grid, colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const abbreviations = ["HRS", "MIN", "SEC"];

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  item: {
    textAlign: 'center'
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  overline: {

  },
  countdown: {
    margin: "auto",
    maxWidth: 200
  }
}));

export default (props) => {
  const { className } = props;
  const classes = useStyles();

  const calculateTimeLeft = (difference) => {

    let timeLeft = {};

    if (difference >= 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    } else {
      timeLeft = {
        hours: 0,
        minutes: 0,
        seconds: 0
      };
    }
    return timeLeft;
  };


  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(props.start_time_of_next_period - +new Date()));

  useEffect(() => {
    var interval = setInterval(async () => {
      const difference = props.start_time_of_next_period - +new Date();
      if (difference <= 0 && typeof props.onRefresh == "function") {
        await props.onRefresh();
      }
      setTimeLeft(calculateTimeLeft(difference));
    }, 1000);
    return function () {
      clearInterval(interval);
    }
  });

  const timerComponents = [];

  const formatNumber = (number) => {
    var strNumber = String(number);
    return strNumber.length < 2 ? "0" + strNumber : strNumber;
  }

  Object.keys(timeLeft).forEach((interval, i) => {
    timerComponents.push(

      <Grid
        className={classes.item}
        item
        xs={4}
        key={`countdown-${i}`}
      >
        <div  >

          <Typography variant="h2">{formatNumber(timeLeft[interval])}</Typography>

        </div>
        <div >
          <Typography
            className={classes.overline}
            variant="overline"
            color="textSecondary"
          >
            {window.innerWidth < 400 ? abbreviations[i] : interval}
          </Typography>

        </div>
      </Grid>
    );
  });

  return (
    <div style={{ margin: "auto"/* , display: "flex", overflow: "hidden" */ }}>
      <Grid container className={classes.countdown}>
        {timerComponents}
      </Grid>
    </div>
  );
};
