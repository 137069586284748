import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';
import CachedIcon from '@material-ui/icons/Cached';
import { withContext } from "src/context";
import { round } from "lodash";
import LogoSimple from 'src/components/LogoSimple';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    boxShadow: "unset"
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700,
    marginBottom: theme.spacing(2)
  },
  avatar: {
    backgroundColor: "red",
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: "red"
  },
  differenceValue: {
    color: "red",
  },
  marginLeft: {
    marginLeft: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(2)
  }
}));

const Budget = props => {
  const { className, value, ...rest } = props;

  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <Grid
          container
          justify="space-between"
        >
          <Grid item style={{ margin: "auto", textAlign: "center" }}>
            <Typography
              className={classes.title}
              gutterBottom
              variant="h5"
            >
              WALLET
            </Typography>
            <div style={{ display: "flex" }}>
              <Typography variant="h2" style={{ whiteSpace: "nowrap", margin: "auto", marginRight: 0 }} >{value} </Typography>
              < LogoSimple style={{ width: 30, height: 30, marginRight: "auto", marginLeft: 8 }} />
            </div>
            {/* <ArrowDownwardIcon className={classes.differenceIcon} /> */}
            <Grid
              container
              style={{ minWidth: 230 }}
            /* justify="space-between" */
            >
              <Grid item xs={4} style={{ margin: "auto", textAlign: "center" }}>
                <Typography
                  variant="overline"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {round(props.conversion_rates.eth * value, 2)} ETH
            </Typography>
              </Grid>
              <Grid item xs={4} style={{ margin: "auto", textAlign: "center" }}>
                <Typography
                  variant="overline"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {round(props.conversion_rates.btc * value, 2)} BTC
            </Typography>
              </Grid>
              <Grid item xs={4} style={{ margin: "auto", textAlign: "center" }}>
                <Typography
                  variant="overline"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {round(props.conversion_rates.usd * value, 2)} USD
            </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </CardContent>
    </Card>
  );
};

Budget.propTypes = {
  className: PropTypes.string
};

export default withContext(Budget);
