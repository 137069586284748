/* eslint-disable no-param-reassign */
import produce from 'immer';
import { SET_CONNECTION_STATUS, SET_IS_CONNECTING, SET_CONNECTION_DATA, DEL_CONNECTION_DATA } from 'src/actions/dappActions';

const initialState = {
  connectionStatus: "disconnected",
  isConnecting: false,
  connectionData: {
    wallet: "",
    network: ""
  }
};

const NETWORKS = ["TEST", "Mainnet", "Morden", "Ropsten", "Rinkeby"];

const dappReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONNECTION_STATUS: {
      const connectionStatus = action.payload;
      return produce(state, (draft) => {
        draft.connectionStatus = connectionStatus;
      });
    }
    case SET_IS_CONNECTING: {
      const status = action.payload;
      return produce(state, (draft) => {
        draft.isConnecting = status;
      });
    }
    case SET_CONNECTION_DATA: {
      return produce(state, (draft) => {
        draft.connectionData = {
          wallet: action.payload.wallet,
          network: NETWORKS[action.payload.network]
        };
      });
    }
    case DEL_CONNECTION_DATA: {
      return produce(state, (draft) => {
        draft.connectionData = initialState.connectionData;
      });
    }

    default: {
      return state;
    }
  }
};

export default dappReducer;
