import React, {
  useRef,
  useState,
  useEffect
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Grid,
  Tooltip,
  Typography,
  makeStyles,
  Card,
  CardMedia,
  colors
} from '@material-ui/core';
import {
  Bell as BellIcon,
  Zap as ZapIcon,
  Package as PackageIcon,
  MessageCircle as MessageIcon,
  Truck as TruckIcon,
  ChevronDown as DownIcon,
  Copy as CopyIcon
} from 'react-feather';
import { withContext } from 'src/context';
import { setIsConnecting, setConnectionStatus, delConnectionData } from 'src/actions/dappActions';
import Slide from '@material-ui/core/Slide';
import { useSnackbar } from 'notistack';
const config = require('src/dapp-config.json');

const TRANSITION_TIMEOUT = 500;

const iconsMap = {
  order_placed: PackageIcon,
  new_message: MessageIcon,
  item_shipped: TruckIcon
};

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  card: {
    width: 140,
    cursor: "pointer"
  },
  avatar: {
    //border: `2px solid ${theme.palette.text.secondary}`,
    margin: "auto",
    background: colors.blue[600],
    height: 22,
    width: 22,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  borderBackground: {
    border: `2px solid ${theme.palette.background.dark}`,
  }
}));

const NETWORKS = ["TEST", "Mainnet", "Morden", "Ropsten", "Rinkeby"];

function Connect({ onToggleDrizzle }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { connectionStatus: status, isConnecting, connectionData } = useSelector((state) => state.dapp);
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (status == "wrongNetwork" || status == "error") handleTryAgain();
  };

  const handleConnect = () => {
    dispatch(setIsConnecting(true));
    onToggleDrizzle();
  }

  const handleDisconnect = () => {
    setOpen(false);
    dispatch(setIsConnecting(false));
    dispatch(setConnectionStatus("disconnected"));
    dispatch(delConnectionData());
    onToggleDrizzle();
  }

  const handleTryAgain = () => {
    handleDisconnect();
  }

  useEffect(() => {
    if ((status == "wrongNetwork" || status == "connected" || status == "error") && isConnecting) {
      dispatch(setIsConnecting(false));
      setOpen(true);
    }
  }, [status]);

  const copyWalletToClipboard = () => {
    var textarea = document.createElement('textarea');
    textarea.textContent = connectionData.wallet;
    document.body.appendChild(textarea);

    var selection = document.getSelection();
    var range = document.createRange();
    //  range.selectNodeContents(textarea);
    range.selectNode(textarea);
    selection.removeAllRanges();
    selection.addRange(range);

    console.log('copy success', document.execCommand('copy'));
    selection.removeAllRanges();

    document.body.removeChild(textarea);
    enqueueSnackbar('Wallet copied to clipboard', {
      variant: 'info',
      autoHideDuration: 2000
    });
  }

  const connect =
    <Box p={2} display="flex" flexDirection="column">
      <Typography
        variant="overline"
        color="textPrimary"
      >
        Connect using:
  </Typography>
      <Box mt={2}>
        <Grid
          alignItems="center"
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item xs={6}>
            <Card className={classes.card} onClick={handleConnect}>
              <Box p={3} display="flex" flexDirection="column" >
                <CardMedia
                  className={classes.media}
                  image={"/static/images/metamask.png"}
                  style={{ margin: "auto" }}
                />
                <Typography
                  variant="h5"
                  color="textSecondary"
                  style={{ margin: "auto" }}
                >
                  Metamask
  </Typography>
              </Box>
            </Card>
          </Grid >
        </Grid >
      </Box>
    </Box>

  const wrongNetwork =
    <Slide direction="right" in={true} mountOnEnter unmountOnExit timeout={TRANSITION_TIMEOUT}>
      <Box p={2} display="flex" flexDirection="column">
        <Typography
          variant="overline"
          color="textPrimary"
        >
          CONNECTION ERROR
</Typography>
        <Box mt={2}>
          <Typography>
            Wrong network
      </Typography>
          <Typography>
            Please select the {NETWORKS[config.network]} network in your wallet and try again.
      </Typography>
          <Box mt={2}>
            <Button
              variant="text"
              color="inherit"
              size="small"
              className={classes.button}
              onClick={handleTryAgain}
            >
              OK, try again
        </Button>
          </Box>

        </Box>
      </Box>
    </Slide>


  const error =
    <Slide direction="right" in={true} mountOnEnter unmountOnExit timeout={TRANSITION_TIMEOUT}>
      <Box p={2} display="flex" flexDirection="column">
        <Typography
          variant="overline"
          color="textPrimary"
        >
          SIGN IN
        </Typography>
        <Box mt={2}>
          <Typography>
            Please sign in to Metamask and select the {NETWORKS[config.network]} network in your wallet and try again.
          </Typography>
          <Box mt={2}>
            <Button
              variant="text"
              color="inherit"
              size="small"
              className={classes.button}
              onClick={handleTryAgain}
            >
              OK, try again
            </Button>
          </Box>
        </Box>
      </Box>
    </Slide>

  const connected =
    <Slide direction="right" in={true} mountOnEnter unmountOnExit timeout={TRANSITION_TIMEOUT}>
      <Box p={2} display="flex" flexDirection="column">
        <Typography
          variant="overline"
          color="textPrimary"
        >
          Connected
</Typography>
        <Box mt={2} display="flex" flexDirection="column">
          <Box display="flex">
            <Typography variant="subtitle2">
              Wallet:
            </Typography>
            <Box ml={1}>
              <Typography color="primary">
                {`${connectionData.wallet.slice(0, 8)}...${connectionData.wallet.slice(-8)}`}
              </Typography>
            </Box>
            <Box ml={1} style={{ cursor: "pointer" }}>
              <CopyIcon onClick={() => copyWalletToClipboard()} size={16} />
            </Box>
            {/* <Button
              size="small"
              color="secondary"
              variant="contained"
              onClick={() => copyWalletToClipboard()}
            >
              Copy to Clipboard
                    </Button> */}
          </Box>
          <Typography style={{ color: "#00bfa5" }}>
            Connected to {connectionData.network} network.
          </Typography>
        </Box>
        <Box mt={2}>
          <Button
            variant="outlined"
            color="inherit"
            size="small"
            className={classes.button}
            onClick={handleDisconnect}
          >
            Disconnect
        </Button>
        </Box>
      </Box>
    </Slide>

  const connectedButton =
    <Button
      variant="text"
      color="inherit"
      size="small"
      ref={ref}
      className={classes.button}
      onClick={handleOpen}
    >
      <Box display="flex" style={{ paddingTop: 4 }}>
        <Box>
          <Box borderRadius="5px" className={classes.avatar} position="relative">
          </Box>
          <Box
            style={{
              background: `#1de9b6`
            }}
            className={classes.borderBackground}
            borderRadius="50%"
            top={19}
            left={16}
            height="12px"
            width="12px"
            position="absolute">
          </Box>
        </Box>
        <Box ml={1}>
          <Typography variant="h6" color="textPrimary" style={{ "font-size": "14px", "line-height": 13, "padding-top": 1 }}>
            {`${connectionData.wallet.slice(0, 5)} ... ${connectionData.wallet.slice(-5)}`}
          </Typography>
          <Typography style={{ color: "#00bfa5", "font-size": "0.6rem", "text-transform": "none", "line-height": 8, "font-weight": 500 }}>
            {`Connected to ${connectionData.network}`}
          </Typography>
        </Box>
        <Box ml={0.5} style={{ paddingTop: 5 }}>
          <DownIcon size={16} />
        </Box>
      </Box>
    </Button >

  return (
    <>
      {status == "connected" ?
        connectedButton
        :
        <Button
          variant="outlined"
          color="inherit"
          size="small"
          ref={ref}
          className={classes.button}
          onClick={handleOpen}
          startIcon={<SvgIcon>
            <ZapIcon />
          </SvgIcon>}
        >
          Connect
        </Button>
      }
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        {status == "disconnected" && connect}
        {status == "wrongNetwork" && wrongNetwork}
        {status == "connected" && connected}
        {status == "error" && error}
      </Popover>
    </>
  );
}

export default withContext(Connect);
