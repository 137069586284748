import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  Typography,
  Grid
} from '@material-ui/core';
import Label from 'src/components/Label';
import {
  colors
} from '@material-ui/core';
import EarningSegmentation from './components/EarningsSegmentation';
import ContributionModule from './components/ContributionModule';

const useStyles = makeStyles(theme => ({
  root: {},
  action: {
    marginRight: 0,
    marginTop: 0
  },
  overview: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start'
    }
  },
  product: {
    display: 'flex',
    alignItems: 'center'
  },
  productImage: {
    marginRight: theme.spacing(1),
    height: 48,
    width: 48
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  notice: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1)
  },
  flex: {
    display: "flex"
  },
  marginLeft: {
    margin: "auto",
    marginLeft: theme.spacing(2)
  },
  item: {
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
}));

const toNumber = (input) => {
  var number = typeof input === "string" ? Number(input.replace(",", ".")) : typeof input === "number" ? input : 0;
  return isNaN(number) ? 0 : number;
}

const Subscription = props => {
  const { className, onContribute, accountCurrentPeriodContribution, periodDistribution, avgRaisedPerPeriod, periodRaised, noMetamask, metamaskLoading, lastTransactionStatus, ...rest } = props;

  const classes = useStyles();
  const [subscription, setSubscription] = useState(null);
  const [contribution, setContribution] = useState(0);

  useEffect(() => {
    let mounted = true;

    const fetchSubscription = () => {

      if (mounted) {
        setSubscription({
          name: 'Freelancer',
          price: '5',
          currency: '$',
          proposalsLeft: 12,
          templatesLeft: 5,
          invitesLeft: 24,
          adsLeft: 10,
          hasAnalytics: true,
          hasEmailAlerts: true
        });
      }

    };

    fetchSubscription();

    return () => {
      mounted = false;
    };
  }, []);

  if (!subscription) {
    return null;
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        action={
          <Label
            color={noMetamask ? metamaskLoading ? "warning" : "error" : "success"}
            variant="contained"
          >
            {noMetamask ? metamaskLoading ? "Metamask Loading... " : "Metamask Disconnected " : "Metamask Connected"}

          </Label>

        }
        classes={{ action: classes.action }}
        title="Contribute"
      />
      <Divider />
      <CardContent style={{ padding: 0 }}>
        <Grid
          container
          justify="space-between"
        >
          <Grid
            item
            className={classes.item}
            md={6}
            sm={12}
            xs={12}>
            <ContributionModule lastTransactionStatus={lastTransactionStatus} periodDistribution={periodDistribution} avgRaisedPerPeriod={avgRaisedPerPeriod} onContribute={onContribute} contribution={contribution} setContribution={setContribution} />
          </Grid>
          <Grid
            item
            className={classes.item}
            md={6}
            sm={12}
            xs={12}>
            <EarningSegmentation contribution={toNumber(contribution)} accountCurrentPeriodContribution={accountCurrentPeriodContribution} periodRaised={periodRaised} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

Subscription.propTypes = {
  className: PropTypes.string
};

export default Subscription;
