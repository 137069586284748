import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { withContext } from 'src/context';
import Bar from './Bar';

const useStyles = makeStyles(theme => ({

}));


const StickyBar = props => {
  const { className, popUp, periodDistribution, currentPeriod, remainingPeriodTime, periodRaised, ...rest } = props;
  const classes = useStyles();
  const [isSticky, setIsSticky] = useState(false);
  const [openAlert, setOpenAlert] = useState(true);

  const stickyRef = useRef(null)

 

  const style = isSticky && openAlert ? {
    position: "fixed",
    top: 52,
    width: stickyRef.current ? stickyRef.current.offsetWidth : "100%"
  } :
    {
      position: "relative",
      top: 0
    }

  return (
    <Fragment>
      <div /* style={{ height: 80 }} */>
        {
          <div ref={stickyRef} >
            <Bar onRefresh={props.onRefresh} style={style} periodRaised={periodRaised} isSticky={isSticky} currentPeriod={currentPeriod} periodDistribution={periodDistribution} remainingPeriodTime={remainingPeriodTime} setOpenAlert={setOpenAlert} openAlert={openAlert} />
          </div>
        }
      </div>
    </Fragment>
  );
};

StickyBar.propTypes = {
  className: PropTypes.string
};

export default withContext(StickyBar);
