import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Grid, colors } from '@material-ui/core';
import Countdown from 'src/components/Countdown';

import Label from 'src/components/Label';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  overline: {
    marginTop: theme.spacing(1)
  },
  countdown: {
    maxWidth: "90%"
  }
}));

const Statistics = props => {
  const { className, periodDistribution, currentPeriod, remainingPeriodTime, ...rest } = props;

  const classes = useStyles();
  const [statistics, setStatistics] = useState(null);

  useEffect(() => {
    let mounted = true;

    /*  const fetchStatistics = () => {
       axios.get('/api/account/statistics').then(response => {
         if (mounted) {
           setStatistics(response.data.statistics);
         }
       });
     };
 
     fetchStatistics(); */

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        /* alignItems="center" */
        container
      /* justify="center" */
      >
        <Grid
          className={classes.item}
          item
          md={3}
          sm={3}
          xs={12}
        >
          <Typography variant="h2">{currentPeriod}</Typography>
          <Typography
            className={classes.overline}
            variant="overline"
          >
            Current period
          </Typography>
        </Grid>
        <Grid
          className={classes.item}
          item
          md={6}
          sm={6}
          xs={12}
        >
          <Countdown start_time_of_next_period={remainingPeriodTime} />
          
        </Grid>
        <Grid
          className={classes.item}
          item
          md={3}
          sm={3}
          xs={12}
        >
          <Typography variant="h2" style={{ whiteSpace: "nowrap" }}>{periodDistribution} MET</Typography>
          <Typography
            className={classes.overline}
            variant="overline"
          >
            Token distribution
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

Statistics.propTypes = {
  className: PropTypes.string
};

export default Statistics;
