import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  Typography,
  Grid
} from '@material-ui/core';
import Label from 'src/components/Label';
import {
  colors
} from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { orderBy, round } from "lodash";
import Timer from "./Timer";
import LogoSimple from 'src/components/LogoSimple';

const useStyles = makeStyles(theme => ({
  root: {},
  action: {
    marginRight: 0,
    marginTop: 0
  },
  overview: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start'
    }
  },
  product: {
    display: 'flex',
    alignItems: 'center'
  },
  productImage: {
    marginRight: theme.spacing(1),
    height: 48,
    width: 48
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  notice: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1)
  },
  flex: {
    display: "flex"
  },
  marginLeft: {
    margin: "auto",
    marginLeft: theme.spacing(2)
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    width: "33%",
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  overline: {
    marginTop: theme.spacing(1)
  },
  bar: {
    //width: theme.breakpoints.values.md,
    [theme.breakpoints.up('md')]: {
      maxWidth: "95%",
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: "100%",
    },
    padding: theme.spacing(0, 3, 3, 3),
    margin: "auto"
  },
  slide: {
    width: "100%"
  },
  margin: {
    height: theme.spacing(3),
  },
  positionMargin: {
    position: "absolute",
    width: "100%",
    transform: "translate(0%,-65%)",
    [theme.breakpoints.up('md')]: {
      //marginTop: -42,
    },
    [theme.breakpoints.down('sm')]: {
      transform: "translate(0%,-65%)",
    },
    [theme.breakpoints.down('xs')]: {
      transform: "translate(0%,-78%)",
    },
  }
}));


const CustomSlider = withStyles(theme => ({
  root: {
    //color: '#16a1ff',
    height: 8,
  },
  thumb: {
    height: 24,
    zIndex: 800,
    width: 24,
    backgroundColor: 'rgba(255,255,255,.92)',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
    // color: colors.indigo[400],
    zIndex: 800
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
}))(Slider);

const CustomSliderHidden = withStyles({
  root: {
    pointerEvents: "none",
    height: 8,
  },
  thumb: {
    color: "unset",
    pointerEvents: "all",
    height: 24,
    width: 24,/* 
    backgroundColor: '#fff', */
    border: '1px solid rgba(193, 206, 241, 0.5)',
    margin: "auto",
    marginTop: -8,
    marginLeft: -1,
    borderRadius: 2,
    width: 2,
    height: 36,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    marginLeft: 0,
  },
  track: {
    pointerEvents: "none",
    height: 8,
    borderRadius: 4,
    visibility: "hidden"
  },
  rail: {
    pointerEvents: "none",
    height: 8,
    borderRadius: 4,
    visibility: "hidden"
  },
  mark: {
    pointerEvents: "all",
    visibility: "hidden"
  },
  markLabel: {
    pointerEvents: "none",
    marginTop: 24,
    whiteSpace: "nowrap",
    "font-size": "0.75rem",
    "font-weight": 500,
    "line-height": 2.66,
    "letter-spacing": "0.08333em",
    "text-transform": "uppercase"
    /* visibility: "hidden" */
  },
  markLabelActive: {
    /* visibility: "inherit" */
  }

})(Slider);

const SaleProgress = props => {
  const { className, periodRaised, tokenSaleStats, ...rest } = props;

  const classes = useStyles();

  var mobile = window.innerWidth < 600;

  const marks = [
    {
      value: !tokenSaleStats ? 0 : tokenSaleStats["2"],
      label: `BEST ${mobile ? "D." : "DAY"}`,
    },
    {
      value: !tokenSaleStats ? 0 : tokenSaleStats["1"],
      label: `${mobile ? "AVG" : "AVERAGE"} ${mobile ? "D." : "DAY"}`,
    },
    {
      value: !tokenSaleStats ? 0 : tokenSaleStats["0"],
      label: `WORST ${mobile ? "D." : "DAY"}`,
    }
  ];


  const getSliderValues = () => {
    var _marks = [...marks];
    _marks.push({
      value: periodRaised,
      label: "RAISED"
    });
    _marks = orderBy(_marks, ['value'], ['asc']);
    var realMax = _marks[3].value;
    var realMin = _marks[0].value;
    var realDiff = realMax - realMin;

    var coef = 0.15;
    var min = round((realMin - (realDiff * coef)), 6);
    var max = round((realMax + (realDiff * coef)), 6);

    _marks = _marks.filter(el => el.label !== "RAISED");

    var threshold = 0.08; // Cambiar esto para el thresh al juntar
    for (var i = _marks.length - 1; i >= 0; i--) {
      if ((i - 1) >= 0) {
        var a = _marks[i].value;
        var b = _marks[i - 1].value;
        if (a - b <= realDiff * threshold) {
          _marks[i - 1].label = `${_marks[i - 1].label}, ${_marks[i].label}`;
          _marks[i].label = "";
        }
      }
    }
    var alreadyZero = false;
    for (var i = 0; i < _marks.length; i++) {
      if (alreadyZero && _marks[i].value == 0) {
        _marks.splice(i, 1);
      } else if (_marks[i].value == 0) {
        alreadyZero = true;
      }
    }

    return {
      min,
      max,
      marks: _marks
    }
  }

  var sliderValues = getSliderValues();

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardHeader
        action={
          <div>
            <Label
              color={"success"}
              style={{ marginLeft: 8 }}
            >
              LIVE
          </Label>
          </div>
        }
        classes={{ action: classes.action }}
        title="Token sale progress"
      />
      <Divider />
      <CardContent >
        <div className={classes.bar} >

          <div className={classes.slide}>
            <div style={{ width: "100%", display: mobile ? "" : "flex" }}>
              <div style={{ marginRight: "auto" }}>
                <Typography color="textSecondary" variant="overline">RAISED CURRENT PERIOD -</Typography>
                <Typography style={{ margin: "auto", marginLeft: 6, whiteSpace: "nowrap" }} variant="overline">{periodRaised} ETH</Typography>
              </div>
              <div style={{ display: "flex" }}>
                <Typography variant="overline" color="textSecondary">TOKEN DISTRIBUTION -</Typography>
                <Typography variant="overline" style={{ marginLeft: 4 }}>10,000</Typography>
                < LogoSimple style={{ width: 15, height: 15, marginTop: 7, marginLeft: 3 }} />
              </div>
            </div>
            <div style={{ marginTop: 26 }}>

              <div key="slider0" style={{ position: "relative", paddingBottom: 24 }}>
                <CustomSlider
                  key="slider0"
                  value={periodRaised}
                  step={10}
                  min={sliderValues.min}
                  max={sliderValues.max}
                  valueLabelDisplay="auto"
                />
                <div className={classes.positionMargin} key="slider1">
                  <CustomSliderHidden
                    key="slider1"
                    value={sliderValues.marks.map(el => el.value)}
                    step={10}
                    track={false}
                    marks={sliderValues.marks}
                    min={sliderValues.min}
                    max={sliderValues.max}
                    valueLabelDisplay="auto"
                  />
                </div>
              </div>


            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

SaleProgress.propTypes = {
  className: PropTypes.string
};

export default SaleProgress;
