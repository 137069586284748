import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  Typography,
  Grid
} from '@material-ui/core';
import Label from 'src/components/Label';
import {
  colors
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {},
  action: {
    marginRight: 0,
    marginTop: 0
  },
  overview: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start'
    }
  },
  product: {
    display: 'flex',
    alignItems: 'center'
  },
  productImage: {
    marginRight: theme.spacing(1),
    height: 48,
    width: 48
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  notice: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1)
  },
  flex: {
    display: "flex"
  },
  marginLeft: {
    margin: "auto",
    marginLeft: theme.spacing(2)
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    width: "33%",
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  overline: {
    marginTop: theme.spacing(1)
  },
  centered: {
    margin: "auto"
  }
}));

const Subscription = props => {
  const { className, totalContribution, currentPeriod, accountCurrentPeriodContribution, periodRaised, ...rest } = props;

  const classes = useStyles();
  const [subscription, setSubscription] = useState(null);

  useEffect(() => {
    let mounted = true;

    const fetchSubscription = () => {

      if (mounted) {
        setSubscription({
          name: 'Freelancer',
          price: '5',
          currency: '$',
          proposalsLeft: 12,
          templatesLeft: 5,
          invitesLeft: 24,
          adsLeft: 10,
          hasAnalytics: true,
          hasEmailAlerts: true
        });
      }

    };

    fetchSubscription();

    return () => {
      mounted = false;
    };
  }, []);

  if (!subscription) {
    return null;
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        action={
          <Button
            size="small"
            variant="contained"
          >
            OTHER PERIODS
          </Button>
        }
        classes={{ action: classes.action }}
        title="Your contribution"
      />
      <Divider />
      <CardContent style={{ padding: 0 }}>
        <Grid
          container
        >
          <Grid
            className={classes.item}
            item
            md={6}
            sm={6}
            xs={12}
          >
            <Typography variant="h2">{totalContribution} ETH</Typography>
            <Typography
              className={classes.overline}
              variant="overline"
            >
              Your Total CONTRIBUTION
          </Typography>
          </Grid>
          <Grid
            className={classes.item}
            item
            md={6}
            sm={6}
            xs={12}
          >
            <Typography variant="h2">{accountCurrentPeriodContribution} ETH</Typography>
            <Typography
              className={classes.overline}
              variant="overline"
            >
              YOUR CONTRIBUTION IN Period {currentPeriod}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent className={classes.details}>
        <div className={classes.centered}>
          <div className={classes.flex}>
            <Label
              color={colors.green[600]}
            >
              LIVE
          </Label>
            <Typography
              variant="body2"
              className={classes.marginLeft}
            >
              You have {periodRaised ? totalContribution / periodRaised : 0} %  of  Tot. contrib. period {currentPeriod}
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

Subscription.propTypes = {
  className: PropTypes.string
};

export default Subscription;
