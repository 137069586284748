import React from 'react';
import DrizzleContext from "./DrizzleContext";

const withDrizzle = Component => props => {
  if (props.withDrizzle) {
    return (
      <DrizzleContext.Consumer>
        {drizzleContext => {
          if (!drizzleContext) return <Component {...props} />
          const { drizzle, drizzleState, initialized } = drizzleContext;
          return (
            <Component {...props} drizzle={drizzle} contracts={drizzle.contracts} drizzleState={drizzleState} initialized={initialized} />
          );
        }}
      </DrizzleContext.Consumer>
    )
  } else {
    return <Component {...props} />
  }
}

const withProvider = Component => props => {
  if (props.withDrizzle) {
    return (
      <DrizzleContext.Provider>
        <Component {...props} />
      </DrizzleContext.Provider>
    )
  } else {
    return <Component {...props} />
  }
}

export { withDrizzle, withProvider };