import MethaToken from "src/contracts/MethaToken.json";
import MethaCrowdsale from "src/contracts/MethaCrowdsale.json";

const options = {
  web3: {
    block: false
  },
  contracts: [MethaToken, MethaCrowdsale],
  events: {
    MethaCrowdsale: ["Mbuy"],
  },
  polls: {
    // set polling interval to 30secs so we don't get buried in poll events
    accounts: 10000,
    blocks: 15000
  },
  syncAlways: true
};

export default options;
