import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Doughnut } from 'react-chartjs-2';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import LogoSimple from 'src/components/LogoSimple';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative'
  }
}));

const Chart = props => {
  const { data: dataProp, className, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();

  const data = {
    datasets: [
      {
        data: [],
        backgroundColor: [],
        borderWidth: 8,
        borderColor: theme.palette.background.default,
        hoverBorderColor: theme.palette.background.default
      }
    ],
    labels: []
  };

  for (const item of dataProp) {
    data.datasets[0].data.push(item.value);
    data.datasets[0].backgroundColor.push(item.color);
    data.labels.push(item.label);
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 65,
    legend: {
      display: false
    },
    layout: {
      padding: 0
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      caretSize: 10,
      yPadding: 20,
      xPadding: 20,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        label: function (tooltipItem, data) {
          const label = data['labels'][tooltipItem['index']];
          const value = data['datasets'][0]['data'][tooltipItem['index']];

          return `${label}: ${value}%`;
        }
      }
    }
  };

  return (
    <div
      {...rest}
    /* className={clsx(classes.root, className)} */
    >
      <div style={{ position: "relative", left: 0, zIndex: 100, height: 210 }}>
        <Doughnut
          data={data}
          options={options}
        /* height={100}
        width={100} */
        //height={200}
        />
      </div>
      <div style={{ position: "relative", display: "flex", flexDirection: "column", top: -130 }}>
        < LogoSimple style={{ width: 40, height: 40, position: "relative", zIndex: 20, margin: "auto" }} />
        <div style={{ borderRadius: 20, width: 52, height: 15, margin: "auto" }}>
          <Typography variant="overline" style={{ position: "relative", top: "-6px" }}>
            x10,000
        </Typography>
        </div>
      </div>
    </div>
  );
};

Chart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired
};

export default Chart;
