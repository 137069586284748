import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withContext } from "src/context";

function AuthGuard({ children, user, authState }) {
  const account = useSelector((state) => state.account);

  /* if (!account.user) {
    return <Redirect to="/login" />;
  } */

  if (authState != "signedIn" || !user || !user.attributes) {
    return <Redirect to="/login" />;
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default withContext(AuthGuard);
