import React from 'react';
import {
  Box,
  Dialog
} from '@material-ui/core';

export default (props) => {
  const { classes, content: _content, onClose } = props;

  const [open, setOpen] = React.useState(false);
  const [content, setContent] = React.useState(_content ? _content : <div></div>);

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  const handleOpen = (content) => {
    setContent(content);
    return setOpen(true);
  };

  const isOpen = () => open;

  React.useEffect(() => { if (props.onRef) props.onRef({ openPopup: handleOpen, closePopup: handleClose, isOpen }) }, []);

  React.useEffect(() => {
    if (typeof props.open === "boolean") {
      if (props.open) setOpen(true);
      else handleClose();
    }
  }, [props.open]);

  React.useEffect(() => {
    if (typeof props.open === "boolean") {
      if (props.open) setOpen(true);
      else handleClose();
    }
  }, [props.open]);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
    >
      <div /* className={classes.root} */>
        <Box
          display="flex"
          justifyContent="space-between"
        > {content}
        </Box>
      </div>

    </Dialog>
  )
}