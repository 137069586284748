import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  TextField,
  InputAdornment,
  Slider,
  Button,
  Avatar,
  colors,
  Grid,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Input,
  Box,
  IconButton,
  SvgIcon,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import uuid from "uuid/v1";
import { Edit as EditIcon } from "react-feather";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import gradients from "src/utils/gradients";
import { withContext } from "src/context";
import SelectPeriodForm from "../SelectPeriodForm.js";
import ContributeButton from "./components/ContributeButton";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "unset",
  },
  content: {
    width: "90%",
    margin: "auto",
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  selection: {
    padding: theme.spacing(1),
    margin: "auto",
    marginTop: "auto",
    width: "100%",
  },
  slider: {
    width: "100%",
    margin: "auto",
  },
  chart: {
    height: 150,
  },
  avatar: {
    backgroundImage: gradients.green,
    height: 48,
    width: 48,
  },
  cardHeader: {
    height: "15%",
  },
  noShadow: {
    boxShadow: "unset",
  },
  details: {
    width: "100%",
  },
  marginTopOne: {
    marginTop: theme.spacing(1),
  },
}));

const getContributionMax = (input) => {
  return 300;
  /*  if(input==0) return 10;
    const refs =[10,50,300,3000,30000];
    const val = refs.map(el=>el-input);

    const out = val.findIndex(el=>el>0);
    console.log(out)
    if(out<0) return refs[refs.length-1];
    else return refs[out]; */
};

const CustomSlider = withStyles({
  root: {
    height: 6,
  },
  thumb: {
    height: 24,
    zIndex: 800,
    width: 24,
    backgroundColor: "rgba(255,255,255,.92)",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
    color: colors.indigo[400],
    zIndex: 800,
  },
  track: {
    height: 6,
    borderRadius: 3,
  },
  rail: {
    height: 6,
    borderRadius: 3,
  },
  mark: {
    pointerEvents: "all",
    visibility: "hidden",
  },
})(Slider);

const ContributionModule = (props) => {
  const {
    className,
    contribution,
    setContribution,
    avgRaisedPerPeriod,
    periodDistribution,
    onContribute,
    popUp,
    ...rest
  } = props;

  const classes = useStyles();

  const maxContrib = 300;
  const [period, setPeriod] = React.useState([0]);
  const periods = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: maxContrib,
      label: `+${maxContrib} ETH`,
    },
  ];

  useEffect(() => {
    let mounted = true;

    return () => {
      mounted = false;
    };
  }, []);

  const handleChange = (event) => {
    event.persist();
    //setContribution(event.target.value === '' ? '' : isNaN(Number(event.target.value)) ? 0 : Number(event.target.value));
    setContribution(event.target.value);
  };

  const handleChangePeriod = (event) => {
    event.persist();
    setPeriod(event.target.value);
  };

  const handleSliderChange = (event, newValue) => {
    setContribution(newValue);
  };

  const handleBlur = () => {
    if (contribution < 0) {
      setContribution(0);
    } else if (contribution > 300) {
      //setContribution(300);
    }
  };

  const toNumber = (input) => {
    var number =
      typeof input === "string"
        ? Number(input.replace(",", "."))
        : typeof input === "number"
          ? input
          : 0;
    return isNaN(number) ? 0 : number;
  };

  var avgTokenReward =
    contribution / avgRaisedPerPeriod > 1
      ? periodDistribution * 0.9
      : (contribution / avgRaisedPerPeriod) * periodDistribution * 0.9;
  avgTokenReward = isNaN(avgTokenReward)
    ? 0
    : Math.floor(avgTokenReward * 100) / 100;

  return (
    <Card className={clsx(classes.root, className)}>
      <CardHeader className={classes.cardHeader} title="New contribution" />

      {/* <Divider /> */}
      <CardContent className={classes.content}>
        <div className={classes.selection}>
          <Grid container justify="space-between" spacing={2}>
            <Grid item md={6} sm={6} xs={12}>
              <Typography style={{ whiteSpace: "nowrap" }} variant="overline">
                Amount
              </Typography>
              <Box mt={2}>
                <TextField
                  fullWidth
                  name="contribution"
                  onChange={handleChange}
                  value={contribution}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start" color="inherit">
                        ETH
                      </InputAdornment>
                    ),
                  }}
                  onBlur={handleBlur}
                  size="small"
                  type="number"
                />
              </Box>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Typography style={{ whiteSpace: "nowrap" }} variant="overline">
                Period
              </Typography>

              <Box mt={2} display="flex">
                <TextField
                  fullWidth
                  name="contribution"
                  onChange={handleChange}
                  value={"Current Period"}
                  variant="outlined"
                  onBlur={handleBlur}
                  size="small"
                  type="string"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {" "}
                        <SvgIcon fontSize="small" color="inherit">
                          <EditIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  onClick={() =>
                    popUp.openPopup(
                      <SelectPeriodForm onCancel={() => popUp.closePopup()} />
                    )
                  }
                />
              </Box>

              {/*  <Select
                labelId="demo-mutiple-name-label"
                id="demo-mutiple-name"
                multiple
                value={period}
                onChange={handleChangePeriod}
                input={<Input />}
                MenuProps={MenuProps}
                >
                {periods.map((period) => (
                    <MenuItem key={period} value={period} >
                    {period==0?"Current period":`Current period +${period}`}
                    </MenuItem>
                ))}
                </Select> */}
            </Grid>
          </Grid>

          <Box mt={2} p={1}>
            <CustomSlider
              className={classes.slider}
              value={toNumber(contribution)}
              onChange={handleSliderChange}
              min={0}
              step={0.1}
              max={maxContrib}
              aria-labelledby="contribution-slider"
              marks={marks}
            />
          </Box>
        </div>
        <Box mt={2} textAlign="center">
          {/* <Button
          color="primary"
          variant="outlined"
          onClick={() => { onContribute(toNumber(contribution),period); setContribution(0); }}
        >
          Contribute
          </Button> */}

          <ContributeButton
            contribution={toNumber(contribution)}
            lastTransactionStatus={props.lastTransactionStatus}
            onContribute={() => {
              var newContribution = contribution;
              setContribution(0);
              return onContribute(toNumber(newContribution), period);
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

ContributionModule.propTypes = {
  className: PropTypes.string,
};

export default withContext(ContributionModule);
