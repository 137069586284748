export const SET_CONNECTION_STATUS = '@dapp/set-notification-status';
export const SET_IS_CONNECTING = '@dapp/set-is-connecting';
export const SET_CONNECTION_DATA = '@dapp/set-connection-data';
export const DEL_CONNECTION_DATA = '@dapp/del-connection-data';

export function setConnectionStatus(status) {
  return (dispatch) => {
    dispatch({
      type: SET_CONNECTION_STATUS,
      payload: status
    });
  };
}

export function setIsConnecting(status) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_CONNECTING,
      payload: status
    });
  };
}

export function setConnectionData(status) {
  return (dispatch) => {
    dispatch({
      type: SET_CONNECTION_DATA,
      payload: status
    });
  };
}

export function delConnectionData(status) {
  return (dispatch) => {
    dispatch({
      type: DEL_CONNECTION_DATA,
      payload: status
    });
  };
}
