import React from 'react';
import {
  makeStyles,
  SvgIcon
} from '@material-ui/core';
import { ReactComponent as Icon } from 'src/assets/images/logo-metha.svg';

const useStyles = makeStyles((theme) => ({
  logo: {
    fill: theme.palette.logo.token,
    overflow: "visible",
    color: theme.palette.logo.tokenBack
  }
}));
function Logo(props) {
  const classes = useStyles();
  return (
    <Icon className={classes.logo} {...props} />
  );
}

export default Logo;
