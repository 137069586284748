import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  colors
} from '@material-ui/core';

import uuid from 'uuid/v1';

import Label from 'src/components/Label';

const useStyles = makeStyles(() => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    /*  width:800 */
  }
}));

const Invoices = props => {
  const { className, currentPeriod, init, getContributorsData, contributorsLengthPerPeriod, ...rest } = props;

  const [data, setData] = useState([])
  const classes = useStyles();

  useEffect(() => {
    getContributorsData()
      .then(data => {
        setData(data);
      })

  }, []);

  useEffect(() => {
    getContributorsData()
      .then(data => {
        setData(data);
      });
  }, [contributorsLengthPerPeriod, init]);


  const statusColors = {
    pending: colors.orange[600],
    paid: colors.green[600],
    rejected: colors.red[600]
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Card>
        <CardHeader
          title="Current period contribution"
        />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Address</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Period</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map(item => (
                    <TableRow key={item.address}>
                      <TableCell>{item.address}</TableCell>
                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        {moment(item.time).format('DD/MM/YYYY | HH:mm:ss')}
                      </TableCell>
                      <TableCell>{currentPeriod}</TableCell>
                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        {`${item.contribution} ETH`}
                      </TableCell>
                      <TableCell>
                        <Label
                          color={statusColors["paid"]}
                          variant="outlined"
                        >
                          {"PAID"}
                        </Label>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
      </Card>
    </div>
  );
};

Invoices.propTypes = {
  className: PropTypes.string
};

export default Invoices;
