import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Grid, colors } from '@material-ui/core';
import { withContext } from 'src/context';
import Button from '@material-ui/core/Button';
import {
  IconButton,
  Paper
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Countdown from 'src/components/Countdown';
import Label from 'src/components/Label';
import LogoSimple from 'src/components/LogoSimple';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  item: {
    padding: theme.spacing(1),
    height: 80,
    textAlign: 'center',
    margin: "auto"
  },
  withSeparator: {
    [theme.breakpoints.up('sm')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('xs')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  withBotSeparator: {
    [theme.breakpoints.down('xs')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  overline: {
    marginTop: theme.spacing(1)
  },
  iFrame: {
    border: "unset",
    width: "100%",
    height: "100%"
  },
  popUp: {
    [theme.breakpoints.up('sm')]: {
      width: "80%",
      height: "80%"
    },
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      height: "100%"
    }
  },
  close: {
    position: "absolute",
    right: 10,
    margin: 20
  },
  wrapper: {
    width: "100%",
    height: "100%"
  },
  alert: {
    marginTop: theme.spacing(3),
    color: "#ffffff",
    backgroundColor: /* colors.indigo[400] */"white"
  },
  button: {
    marginLeft: theme.spacing(3)
  },
  normal: {
    /* zIndex: 900, */
    transition: 'all 300ms linear',
    transitionDuration: "0.5s"
  },
  gridWrapper: {
  }
}));
var iframeRef;

const Bar = props => {
  const { className, popUp, periodDistribution, periodRaised, currentPeriod, remainingPeriodTime, width, isSticky, position, style, setOpenAlert, openAlert, ...rest } = props;
  const classes = useStyles();

  const handleAlertClose = () => {
    setOpenAlert(!openAlert);
  };

  return (

    <Paper
      className={clsx(classes.root, className)}
      elevation={1}
    >

      <Grid
        /* alignItems="center" */
        container
        className={classes.gridWrapper}
      /* justify="center" */
      >
        <Grid
          className={`${classes.item} ${classes.withSeparator}`}
          key="total-raised"
          item
          md={3}
          sm={3}
          xs={12}
        >
          <Typography variant="h2" >{periodRaised} ETH</Typography>
          <Typography
            className={classes.overline}
            variant="overline"
            color="textSecondary"
          >
            TOTAL RAISED THIS PERIOD
          </Typography>
        </Grid>
        <Grid
          className={`${classes.item} ${classes.withBotSeparator}`}
          key="total-distribution"
          item
          md={3}
          sm={3}
          xs={6}
        >
          <div style={{ display: "flex" }}>
            <Typography variant="h2" style={{ whiteSpace: "nowrap", margin: "auto", marginRight: 0 }}>{"10,000"} </Typography>
            < LogoSimple style={{ width: 30, height: 30, marginRight: "auto", marginLeft: 8 }} />
          </div>

          <Typography
            className={classes.overline}
            style={{ whiteSpace: "nowrap" }}
            variant="overline"
            color="textSecondary"
          >
            TOKEN DISTRIBUTION
              </Typography>

        </Grid>
        <Grid
          className={`${classes.item} ${classes.withSeparator}`}
          item
          key="remaining-time"
          md={3}
          sm={3}
          xs={6}
        >
          <div style={{ marginTop: 3 }}>
            <Countdown onRefresh={props.onRefresh} start_time_of_next_period={remainingPeriodTime} />
          </div>
        </Grid>
        <Grid
          className={classes.item}
          item
          key="current-period"
          md={3}
          sm={3}
          xs={12}
        >
          <Typography variant="h2" >#{currentPeriod}</Typography>
          <Typography
            className={classes.overline}
            variant="overline"
            color="textSecondary"
          >
            CURRENT PERIOD
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

Bar.propTypes = {
  className: PropTypes.string
};

export default withContext(Bar);
