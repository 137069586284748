import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Grid, colors } from '@material-ui/core';
import { withContext } from 'src/context';
import Button from '@material-ui/core/Button';
import {
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
//import Alert from 'components/Alert';

import Label from 'src/components/Label';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    width: "33%",
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  overline: {
    marginTop: theme.spacing(1)
  },
  iFrame: {
    border: "unset",
    width: "100%",
    height: "100%"
  },
  popUp: {
    [theme.breakpoints.up('sm')]: {
      width: "80%",
      height: "80%"
    },
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      height: "100%"
    }
  },
  close: {
    position: "absolute",
    right: 10,
    margin: 20
  },
  wrapper: {
    width: "100%",
    height: "100%"
  },
  alert: {
    marginTop: theme.spacing(0),
    color: "#ffffff",
    backgroundColor: "white"
  },
  button: {
    marginLeft: theme.spacing(3)
  }
}));
var iframeRef;
const Statistics = props => {
  const { className, popUp, ...rest } = props;


  const classes = useStyles();
  const [statistics, setStatistics] = useState(null);
  const [openAlert, setOpenAlert] = useState(true);
  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const iframe = (
    <div className={classes.wrapper}>
      <IconButton
        onClick={() => popUp.closePopup()}
        size="small"
        className={classes.close}
      >
        <CloseIcon />
      </IconButton>
      <iframe id="survey-frame" ref={(ref) => window.iframeRef = ref} className={classes.iFrame} src="https://josevictormadrid90.typeform.com/to/Z3sNGH"></iframe>
    </div>
  )
  useEffect(() => {
    let mounted = true;
    /*  var interval = setInterval(function () {
 
 
       var iframe = document.getElementById("survey-frame");
       if (iframe) {
         var elmnt = iframe.contentWindow.document.querySelectorAll('[data-qa*="submit"]');
 
         console.log(elmnt);
       }
 
       //console.log(iframeRef.querySelector('[data-qa="submit-button deep-purple-submit-button"]'))
 
     }, 1000); */

    return () => {
      //clearInterval(interval);
      mounted = false;
    };
  }, []);

  const openAndCatch = () => {
    popUp.openPopup(iframe, { className: classes.popUp, noFrame: true });


    /*  while (true) {
       console.log(document.querySelector('[data-qa="ok-button-visible deep-purple-ok-button-visible"]'))
     } */
    /* document.querySelector('[data-qa="ok-button-visible deep-purple-ok-button-visible"]') */
  }

  return (
    <Fragment>
     {/*  {openAlert &&
        <Alert
          className={classes.alert}
          message={<div style={{ display: "flex", color: "white" }}>
            <Typography style={{ margin: "auto" }}>
              Complete the survey and earn free Metha Tokens on launch!
            </Typography>
            <Button
              className={classes.button}
              size="small"
              variant="outlined" onClick={openAndCatch}> Survey</Button>
          </div>
          }
          onClose={handleAlertClose}
        />
      } */}
    </Fragment>
  );
};

Statistics.propTypes = {
  className: PropTypes.string
};

export default withContext(Statistics);
